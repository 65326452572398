<template>
  <div class="clearfix">
    <!-- 单张图片 -->
    <!-- <a-upload v-if="count === 1" name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76" :before-upload="beforeUpload" @change="handleChange">
      <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
      <div v-else>
        <a-icon :type="loading ? 'loading' : 'plus'" />
        <div class="ant-upload-text">
          上传
        </div>
      </div>
    </a-upload> -->
    <!-- 多图 -->
    <a-upload
      :disabled="disabled"
      :file-list="fileList"
      :list-type="type == 'image' ? 'picture-card' : 'picture'"
      :show-upload-list="true"
      :headers="headers"
      :before-upload="beforeUpload"
      :customRequest="handleChange"
      @change="changeImg"
      @preview="handlePreview"
    >
      <!-- <div v-if="type == 'image'">
        <div v-if="fileList.length" v-for="item in fileList" :key="index">
          <img :src="item.path" alt="img" />
        </div>
      </div> -->
      <!-- 文件、视频类型 -->
      <div v-if="type == 'file' || type == 'video'">
        <a-button> <a-icon type="upload" /> 上传 </a-button>
      </div>
      <div v-if="!disabled && fileList.length < count">
        <span v-if="type == 'image'">
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">上传</div>
        </span>
      </div>
    </a-upload>
    <span v-if="value && type == 'file'">
      <br />
      <a :href="value[fieldName]" target="_blank">{{ value[fieldName] }}</a>
    </span>
    <!-- <a-modal width="100%" :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal> -->
    <!-- 预览 -->
    <div class="preview-mask" v-if="previewVisible" @click="previewVisible = false">
      <div class="preview-body">
        <a-icon class="preview-close" type="close" />
        <img v-if="previewImage && type == 'image'" :src="previewImage" @click.stop />
        <video v-if="previewImage && type == 'video'" :src="previewImage" />
      </div>
    </div>
  </div>
</template>

<script>
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import UploadFileToOSS from '@/utils/upload-file-to-oss.js'
export default {
  name: 'ImageUpload',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default() {
        return {}
      }
    },
    // 上传的文件类型 image图片 file文件
    type: {
      type: String,
      default: 'image'
    },
    count: {
      type: Number,
      default: 1
    },
    defaultList: {
      type: Array,
      default() {
        return []
      }
    },
    fieldName: {
      type: String,
      default: ''
    },
    form: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {},
  created() {
    let that=this
    console.log('created')
    // console.log('defaultList', this.defaultList)
    // this.fileList = []
    console.log('fileList111', that.fileList)
    console.log('value', that.fieldName)

    if (that.value[that.fieldName]) {
      let fileUrlTemp =that.value[that.fieldName].split(',');
      debugger
      for(let i=0;i<fileUrlTemp.length;i++){
        that.fileList.push({
          uid: '000021'+i,
          url:fileUrlTemp[i],
          cover: ''
        })
      }
      console.log('fileList', that.fileList)
    }
  },
  mounted() {
    console.log('mounted')
  },
  beforeUpdate() {
    let that=this
    // 修复更新的bug
    if (!that.value[that.fieldName]) {
      that.fileList = []
    } else {
      that.fileList = [
        {
          uid: '000021',
          url: that.value[that.fieldName],
          status: 'done',
          name: that.value[that.fieldName]
        }
      ]
    }
  },
  data() {
    return {
      loading: false,
      open: false,

      uploadImgUrl: process.env.VUE_APP_BASE_API + '/common/upload',
      headers: {
        Authorization: 'Bearer ' + storage.get(ACCESS_TOKEN)
      },
      previewVisible: false,
      previewImage: '',
      fileList: []
      // fileList: [
      // {
      //   "uid": "72968121",
      //   "name": "5938e139-d83d-4b50-b900-614154d6043e.png",
      //   "status": "done",
      //   "path": "https://static.xiaoxinxin.com/2023-08-10/5938e139-d83d-4b50-b900-614154d6043e.png",
      //   "url": "https://static.xiaoxinxin.com/2023-08-10/5938e139-d83d-4b50-b900-614154d6043e.png",
      //   "cover": ""
      // },
      // {
      //   uid: '-1',
      //   name: 'image.png',
      //   status: 'done',
      //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      // },
      // ]
    }
  },
  methods: {
    beforeUpload(file) {
      // 文件类型(file.type)、大小限制(file.size)
      var isLt2M = true
      if(this.type==='image'){
        isLt2M = file.size / 1024 / 1024 < 2 * 10
        if (!isLt2M) {
          this.$message.error('大小限制 20MB!')
        }
      }else{
        isLt2M = file.size / 1024 / 1024 < 2 * 100
        if (!isLt2M) {
          this.$message.error('大小限制 200MB!')
        }
      }

      return isLt2M
    },
    async handleChange(info) {
      console.log('info上传图片得到的数据', info)
      // if (info.file.status === 'uploading') {
      //   this.loading = true
      //   return
      // }
      // if (info.file.status === 'done') {
      //   if (info.file.response.code !== 200) {
      //     this.$message.error('上传失败' + info.file.response.msg)
      //     this.loading = false
      //     return
      //   }
      //   this.loading = false
      //   // this.$emit('input', info.file.response.url)
      // }
      this.loading = true
      new UploadFileToOSS([info.file], {
        finally: (res) => {
          console.log('上传成功回调', res)
          this.loading = false
          // let m2 = { name: res.name, url: res.url, status: 'done', uid: '000021'+(this.fileList.length+1) }
          this.fileList = this.fileList.concat(res)
          console.log('fileList', this.fileList)

          // console.log('ref', this.value)
          this.getImg(this.fileList, this.fieldName)
        }
      })
    },
    getImg(info, fieldName) {
      debugger

      // let infoArr=[];
      // if(info.length>1){
      //   let allUrl=info[0].url.split(',')
      //   for (let i = 0; i <allUrl.length ; i++) {
      //
      //     var index = allUrl[i].lastIndexOf("\/");
      //     let newName = allUrl[i].substr(index + 1);
      //     let m1 = { name: newName, url: allUrl[i], status: 'done', uid: '000021' + i }
      //     infoArr.push(m1);
      //
      //     console.log(infoArr);
      //   }
      //   let ttt =info[info.length-1]
      //   let m2 = { name: ttt.name, url: ttt.url, status: 'done', uid: '000021' +(allUrl.length+1) }
      //   infoArr.push(m2)
      // }
      // this.fileList=infoArr

      // let infonew=[];
      //   let ttt =info[info.length-1]
      //   let m2 = { name: ttt.name, url: ttt.url, status: 'done', uid: '000021'+(info.length+1) }
      // infonew[0]=m2
      // if(info.length>1){
      //   infonew[0].url+=','+info[1].url
      //   info=infonew;
      // }
      console.log('info23333', info)

      const toChangeForm = {}
      if (info.length === 0) {
        toChangeForm[fieldName] = ''
        toChangeForm[`${fieldName}Arr`] = []
      } else {
        if (toChangeForm[`${fieldName}Count`] === 1) {
          toChangeForm[fieldName] = info[0].url
          toChangeForm[`${fieldName}Arr`] = info
        } else {

          toChangeForm[fieldName] = info
            .map((ele) => {
              return ele.url
            })
            .join(',')
          toChangeForm[`${fieldName}Arr`] = info
        }
      }
      console.log('toChangeForm', toChangeForm)
      this.$emit('input', { ...this.value, ...toChangeForm })
    },
    changeImg(info) {
      console.log('info', info)
      let that=this
      // {
      //   "file": {
      //       "uid": "48718960",
      //       "name": "aaa002fd-22dc-4e42-9f56-8335ed3e8e92.png",
      //       "status": "removed",
      //       "path": "https://static.xiaoxinxin.com/2023-08-10/aaa002fd-22dc-4e42-9f56-8335ed3e8e92.png",
      //       "url": "https://static.xiaoxinxin.com/2023-08-10/aaa002fd-22dc-4e42-9f56-8335ed3e8e92.png",
      //       "cover": ""
      //   },
      //   "fileList": []
      // }
      debugger
      if (info.file.status === 'removed') {
        // 删除图片
        if (that.count === 1) {
          that.value[that.fieldName] = ''
          that.fileList = []
        } else {
          const index = that.fileList.findIndex((ele) => ele.uid === info.file.uid)
          that.fileList.splice(index, 1)
        }
        that.$forceUpdate()
      }
    },
    /** 图片预览 */
    async handlePreview(file) {
      console.log('预览', file)
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
img {
  width: 128px;
  height: 128px;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
