<template>
  <div :class="wrpCls">
    <a-space size="middle">
<!--      <a-tooltip placement="bottom" v-if="roleId == 2">-->
      <a-tooltip placement="bottom">
        <template slot="title">
          平台客服
        </template>
        <a-icon type="github" @click="loginKf" :style="{ fontSize: '20px' }"/>
      </a-tooltip>
<!--      <a-tooltip placement="bottom">-->
<!--        <template slot="title">-->
<!--          源码地址-->
<!--        </template>-->
<!--        <a-icon type="github" @click="toGithub" :style="{ fontSize: '20px' }"/>-->
<!--      </a-tooltip>-->
<!--      <a-tooltip placement="bottom">-->
<!--        <template slot="title">-->
<!--          文档地址-->
<!--        </template>-->
<!--        <a-icon type="question-circle-o" @click="toDoc" :style="{ fontSize: '20px' }"/>-->
<!--      </a-tooltip>-->
      <screenfull />
<!--      <notice-icon v-hasPermi="['system:notice:list']" style="cursor: pointer;"/>-->
      <avatar-dropdown :menu="showMenu" :current-user="currentUser" :class="prefixCls" />
      <!-- 暂只支持中文，国际化可自行扩展 -->
      <!-- <select-lang :class="prefixCls" /> -->
    </a-space>
  </div>
</template>

<script>
import AvatarDropdown from './AvatarDropdown'
import NoticeIcon from '@/components/NoticeIcon'
import Screenfull from '@/components/Screenfull'
import SelectLang from '@/components/SelectLang'
import request from "@/utils/request";

export default {
  name: 'RightContent',
  components: {
    AvatarDropdown,
    NoticeIcon,
    Screenfull,
    SelectLang
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action'
    },
    isMobile: {
      type: Boolean,
      default: () => false
    },
    topMenu: {
      type: Boolean,
      required: true
    },
    theme: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showMenu: true,
      currentUser: {},
      docUrl: process.env.VUE_APP_BASE_API +'/doc',
      githubUrl: 'http://gitlab.niurenjianzhan.com/zhangxun/java-framework'
    }
  },
  computed: {
    wrpCls () {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${(this.isMobile || !this.topMenu) ? 'light' : this.theme}`]: true
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.currentUser = {
        name: 'RuoYi'
      }
    }, 1500)
  },
  methods: {
    toDoc () {
      window.open(this.docUrl)
    },
    getRole(){
      let that = this;
      request({
        url: '/system/user/getRole',
        method: 'post'
      }).then(response => {
        that.roleId = response.data;
      })
    },
    loginKf() {
      request({
        url: '/system/config/getValue?configGroup=customerService&configKey=line',
        method: 'get'
      }).then(response => {
        // console.log('config : ',response.data);
        let id = response.data;
        let name = "梦之投客服";

        let avatar = "https://mzttwl.oss-cn-beijing.aliyuncs.com/default/kf.png";
        // let avatar = "https://shijue-2023.oss-cn-hangzhou.aliyuncs.com/default/1105447514255003648.png";
        // https://mzttwl.oss-cn-beijing.aliyuncs.com/default/kf.png

        // var token = JSON.parse(this.getLocalItem("pro__Access-Token")).value;
        // window.open('http://localhost:63342/gather-tencentkf/tencentkf?id='+id+"&avatar="+avatar+"&name="+name, 'kfWidow',"444");
        // window.open('http://app.hzryzr.com/tencentkf?id='+id+"&avatar="+avatar+"&name="+name, 'kfWidow',"444");
        window.open('https://app.mzttwl.com/tencentkf?id='+id+"&avatar="+avatar+"&name="+name, 'kfWidow',"444");
      })
    },
    toGithub () {
      window.open(this.githubUrl)
    }
  }
}
</script>
