import axios from 'axios'
import notification from 'ant-design-vue/es/notification'
import { getOssConfig } from '@/api/system/upload.js'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { guid } from '@/utils/ruoyi.js'

export default class UploadFileToOSS {

  /**
   * [constructor description]
   * @param  {[Array]} files [chooseImg选中的tempFilePaths、chooseVideo选中的rempFilePath]
   * @param  {[Object]} json  [success每上传成功一张调用 complete全部上传完成调用]
   * @return {[void]}       [description]
   */

  constructor(files, json) {
    if (!Array.isArray(files)) {
      throw new Error('Class UploadFileToOSS parameter must be an array');
    }
    console.log('files, json', files, json)
    this.data = [];
    this.fn = json.success;
    this.complete = json.finally;
    this.files = files;
    this.fileLen = this.files.length;
    this.curIndex = 0;
    // notification.info({ message: '上传中' })
    this.upload();
  }

  async upload() {
    const token = storage.get(ACCESS_TOKEN)
    // 随机生成一个文件名，并且按照日期归类到二级目录下，否则很容易产生覆盖
    const dates = new Date()
    const year = dates.getFullYear()
    const month = dates.getMonth() + 1
    const date = dates.getDate()
    const nowDate = year + '-' + (month < 10 ? `0${month}` : month) + '-' + (date < 10 ? `0${date}` : date)
    const bmonth = month < 10 ? `0${month}` : month
    const bdate = date < 10 ? `0${date}` : date
    getOssConfig().then(res => {
      let ossData = res.data
      let form = new FormData()
      console.log("this.files[0].name:",this.files)
      let name = this.files[0].name.split("\.")[1]
      form.append('key', `${year}/${bmonth}/${bdate}/` + ossData.fileName + '.' + name)
      form.append('policy', ossData.policy)
      form.append('OSSAccessKeyId', ossData.accessid)
      form.append('success_action_status', '200')
      form.append('signature', ossData.signature)
      form.append('file', this.files[0])
      axios({
        url: ossData.uploadUrl,
        method: 'post',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': 'attachment;filename=' + encodeURIComponent(ossData.fileName)
        },
        data: form,
      }).then(res => {
        console.log('res', res)
        if (res.status === 200) {
          let url = ossData.resultUrl + `/${year}/${bmonth}/${bdate}/` + ossData.fileName + '.' + name
          this.data.push({
            uid: guid(8, 10),
            status: 'done',
            name: ossData.fileName + '.' + name,
            halfUrl: `${year}/${bmonth}/${bdate}/` + ossData.fileName + '.' + name,
            path: url,
            url: url,
            cover: ''
          });
          if (this.fn) this.fn(this.data);
        } else {
          notification.error('上传失败，请重试')
        }
      }).finally(res => {
        console.log('结束', res)
        if (this.fn) this.fn(this.data);
        this.curIndex++; // 当前文件执行完上传后，开始上传下一张
        if (this.curIndex == this.fileLen) { // 当文件传完时，停止调用
          this.complete(this.data);
        } else { // 若文件还没有传完，则继续调用函数
          this.upload();
        }
      })
    })
  }
}